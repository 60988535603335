<template>
  <div class="about">
    <img-show></img-show>
    <div class="main_about">
      <Production></Production>
    </div>
    <div class="main_about">
      <Brand></Brand>
    </div>
  </div>
</template>
<script>
import ImgShow from "@/components/ImgShow";
import Production from "./components/Production";
import Brand from './components/Brand'
export default {
  components: { ImgShow, Production ,Brand},
};
</script>
<style lang="scss" scoped>
.about {
  text-align: center;
  .main_about {
    padding: 0 200px;
    text-align: center;
    margin-top:50px;
    margin-bottom: 50px;
  }
}
</style>
