<template>
  <div class="Brand">
    <h3>理念</h3>
    <div class="explain">企业愿景：世界知勇哥，有车必有勇哥。</div>
    <div class="explain">企业精神：团队协作 、勇于开拓 。</div>
    <div class="explain">企业价值观：用爱呵护，减轻司乘架车的辛苦。</div>
    <div class="explain">
      企业的经营理念：以创新的产品。卓越的品质，立企业之本。用优质的服务得顾客之心
    </div>
    <div class="explain">企业的使命：打造行业第一品牌。</div>
    <div class="componey_img">
      <img src="../../../assets/Home/production1.png" alt="" />
      <img src="../../../assets/Home/production2.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.Brand {
  h3 {
    line-height: 50px;
    text-align: left;
  }
  .explain {
    text-align: left;
    color: #aeaeae;
    line-height: 28px;
  }
  .componey_img {
    display: flex;
    //   justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      width: 327px;
      height: 230px;
      margin-right: 20px;
    }
  }
}
</style>
