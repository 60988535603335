<template>
  <div class="self_production">
    <h3>公司介绍</h3>
    <div class="explain">
      东莞市勇哥汽车配件有限公司，是汽车专用可调节颈枕等产品专业生产加工的公司，成立于2012年11月。公司拥有科学的质量管理体系，及开发团队6人，专业的技术生产团队80人，一流服务的销售团队10人，中高级管理团队6人。我们秉承专而精,用爱呵护，减轻司乘架车的辛苦,以创新的产品。我们追求卓越的品质，用优质的服务得顾客之心。
    </div>
    <div class="componey_img">
        <img src="../../../assets/Home/production1.png" alt="">
        <img src="../../../assets/Home/production2.png" alt="">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.self_production {
    h3{
        line-height: 50px;
    }
  .explain {
    //   text-align: left;
      color:#AEAEAE;
      line-height: 28px;
  }
  .componey_img{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      img{
          width: 327px;
          height: 230px;
          margin-right: 20px;
      }
  }
}
</style>
